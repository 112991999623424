html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  font-family: "Courier New", Courier, monospace;
}

::-webkit-scrollbar {
  display: none;
}

.auth-page-container {
  max-width: 450px;
  padding: 15px;
  margin: 0 auto;
  border: 1px solid #ccc;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media (max-width: 500px) {
  .auth-page-container {
    border: none;
  }
}

@media (max-width: 407px) {
  html,
  body {
    font-size: 14px;
  }
}

@media (max-width: 360px) {
  html,
  body {
    font-size: 12px;
  }
}

@media (max-width: 305px) {
  html,
  body {
    font-size: 10px;
  }
}
