.loading-spinner {
  margin: auto;
  text-align: center;
  margin-top: 10px;
}

.loading-text {
  margin-top: 40px;
  font-size: larger;
}
