.monologue-card {
  border: 1px solid #ccc;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
}

.monologue-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 10px;
}

.monologue-details {
  font-size: 14px;
  line-height: 1.6;
  text-align: left;
}

.monologue-title,
.monologue-similarity {
  font-weight: bold;
}

.monologue-title {
  margin-right: 8px;
}

.monologue-details div {
  margin: 8px;
}

.monologue-text-container {
  text-align: center;
  margin: auto;
  padding: 10px;
  text-decoration: wavy;
}

.view-more {
  cursor: pointer;
  color: #007bff;
  margin-top: 8px;
  margin-left: auto;
}
