.monologue-form {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  max-width: calc(1024px - 8%);
}

.header-container {
  position: relative;
  width: 100%;
  max-height: 70px;
  text-align: center;
}

hr {
  opacity: 0.5;
}

h3 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.errmsg {
  text-align: center;
  color: #e74c3c;
  margin: 10px;
  font-size: 14px;
}

form {
  display: flex;
  flex-direction: column;
}

.form-element-pair {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

label {
  margin-bottom: 10px;
  padding-left: 12px;
  margin-right: -6px;
  text-align: left;
  flex-basis: 48%;
}

button {
  padding: 10px 20px;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 98%;
  height: 50px;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: white;
  cursor: pointer;
  font-weight: 700;
  font-size: large;
}

button:hover {
  background-color: #555;
}

.monologue-form-element-title {
  margin-left: 2px;
  margin-bottom: 5px;
}

.input-component {
  margin-bottom: 10px;
  border-radius: 5px;
  width: 95%;
  height: 30px;
}

.error {
  border-color: #e74c3c;
}

.select-error div {
  border-color: #e74c3c;
}

.select-error:hover div {
  border-color: #e74c3c;
}

.required-label-error {
  color: #e74c3c;
}

textarea,
input {
  font-family: inherit;
  font-size: inherit;
  padding-top: 5px;
  padding-left: 9px;
  border: 1px solid #ccc;
}

.input-multi-component {
  width: 92.5%;
  border-radius: 5px;
  margin-bottom: -3px;
}

.singe-text-input {
  width: 92.5%;
  border-radius: 5px;
  height: 30px;
  margin-bottom: 3px;
}

.header-text {
  display: inline-block;
  vertical-align: middle;
}

.arrow-icon {
  position: absolute;
  right: 30px;
  width: 10px;
  height: 10px;
  border-left: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  transform: rotate(45deg);
  vertical-align: middle;
  transition: transform 0.3s ease;
}

.form-subtitle {
  margin-top: 30px;
  margin-bottom: 30px;
}

.main-arrow {
  top: 30px;
}

.actor-arrow {
  top: 25px;
}

.arrow-icon.down {
  transform: rotate(-45deg);
}

.arrow-icon.up {
  transform: rotate(135deg);
  margin-top: 6px;
}

.clear-form-button {
  margin-top: -5px;
  background-color: grey;
}

.form-required-label {
  font-size: 12px;
  margin-left: 5px;
  opacity: 0.5;
}

.exclusive-box-container {
  font-size: 14px;
  margin-top: 2px;
  opacity: 0.7;
}

.exclusive-checkbox {
  height: 12px;
  width: 12px;
  margin-right: 5px;
}

.slider-container {
  text-align: center;
}

.time-range-indicator {
  margin: auto;
  margin-top: 10px;
  font-size: 13px;
}

@media (max-width: 1280px) {
  .input-multi-component {
    width: 91%;
    border-radius: 5px;
  }

  .singe-text-input {
    width: 91%;
    border-radius: 5px;
    height: 30px;
  }
}

@media (max-width: 606px) {
  .form-element-pair {
    flex-direction: column;
  }

  .input-multi-component {
    width: 92.5%;
    border-radius: 5px;
  }

  .singe-text-input {
    width: 92.5%;
    border-radius: 5px;
    height: 30px;
  }
}

@media (max-width: 510px) {
  .arrow-icon {
    right: 17px;
  }

  .main-arrow {
    top: 25px;
  }

  .actor-arrow {
    top: 20px;
  }
}

@media (max-width: 455px) {
  .input-multi-component {
    width: 91%;
    border-radius: 5px;
  }

  .singe-text-input {
    width: 91%;
    border-radius: 5px;
    height: 30px;
  }
}

@media (max-width: 407px) {
  .arrow-icon {
    width: 8px;
    height: 8px;
    right: 10px;
  }
  .main-arrow {
    top: 23px;
  }

  .actor-arrow {
    top: 18px;
  }
}

@media (max-width: 360px) {
  .arrow-icon {
    width: 6px;
    height: 6px;
  }
  .main-arrow {
    top: 20px;
  }

  .actor-arrow {
    top: 15px;
  }
}

@media (max-width: 305px) {
  .arrow-icon {
    width: 5px;
    height: 5px;
  }
  .main-arrow {
    top: 17px;
  }

  .actor-arrow {
    top: 12px;
  }
}
