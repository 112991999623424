.home-container {
  text-align: center;
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.home-main-title {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: "Courier New", Courier, monospace;
  font-weight: 400;
}

.home-subtitle {
  margin-top: -10px;
  margin-bottom: 20px;
  width: 90%;
  line-height: 22px;
  font-size: 1;
}

.home-answer-line {
  margin: 10px;
}

.home-answer-container {
  margin-bottom: 20px;
  width: 100%;
}

@media (max-width: 1280px) {
  .home-container {
    width: 70%;
  }
}

@media (max-width: 990px) {
  .home-container {
    width: 80%;
  }
}

@media (max-width: 870px) {
  .home-container {
    width: 90%;
  }
}

@media (max-width: 770px) {
  .home-main-title {
    width: 80%;
  }
}

@media (max-width: 670px) {
  .home-main-title {
    width: 100%;
  }
}
