.footer-container {
  text-align: center;
  padding: 5px 5px;
  background-color: #333;
  color: white;
  margin-bottom: 0;
  margin-top: auto;
}

.contact-container {
  margin-top: 10px;
}

.copyright-container {
  margin: 5px;
}

.email-link {
  color: white;
  text-decoration: underline;
}
