.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 30px;
  height: 40px;
  background-color: #333;
  margin-bottom: 20px;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  margin: 0;
  color: #fff;
  font-size: 24px;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 15px;
}

.menu li {
  margin: 0;
}

.menu a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.menu a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* .logo:hover {
  color: #89cff0;
  cursor: pointer;
} */

@media (max-width: 530px) {
  .navbar {
    height: 50px;
  }

  .logo {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media (max-width: 407px) {
  .navbar {
    padding: 5px 15px;
  }
  .logo {
    font-size: 20px;
  }
}

@media (max-width: 360px) {
  .logo {
    font-size: 18px;
  }
}
